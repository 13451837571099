import Vue from 'vue'
import { throttle } from 'throttle-debounce'

export default new Vue({
    created() {
        window.addEventListener('scroll', throttle(50, this._handleScroll.bind(this)))
    },
    methods: {
        _handleScroll(e) {
            const top = (window.pageYOffset || document.scrollTop) - (document.clientTop || 0)

            this.$emit('scroll', isNaN(top) ? 0 : top, e)
        },
    },
})
