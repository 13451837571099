var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "context-container" },
    [
      _vm.bound ? _c("div", { staticClass: "base" }) : _vm._e(),
      _c(
        "line-item",
        { class: { bare: _vm.bare, bound: _vm.bound, "context-title": true } },
        [
          _c(
            "div",
            {
              staticClass: "main",
              class: { space_between: this.spaceBetween },
            },
            [
              !!_vm.$slots.title
                ? _c("div", [_vm._t("title")], 2)
                : _c(
                    "div",
                    { staticClass: "text-h6" },
                    [
                      _c("v-breadcrumbs", {
                        attrs: {
                          items: _vm.displayTitle
                            ? _vm.breadcrumbDisplayItems
                            : _vm.breadcrumbItems,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "divider",
                            fn: function () {
                              return [
                                _c("span", { staticClass: "title-delimiter" }),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "item",
                            fn: function ({ item }) {
                              return [
                                _c(
                                  "v-breadcrumbs-item",
                                  { attrs: { disabled: item.disabled } },
                                  [
                                    item.disabled
                                      ? _c(
                                          "span",
                                          { staticClass: "breadcrumb-item" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.text) + " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "router-link",
                                          {
                                            staticClass: "breadcrumb-item",
                                            attrs: { to: item.to },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.text) + " "
                                            ),
                                          ]
                                        ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
              _c(
                "div",
                { staticClass: "primary-buttons" },
                [
                  _vm._l(_vm.actions, function (action, i) {
                    return _c(
                      "action-button",
                      {
                        key: i,
                        attrs: {
                          type: action.type,
                          action: _vm.runAction(action.run),
                          noIcon:
                            action.noIcon === undefined ? false : action.noIcon,
                        },
                      },
                      [
                        action.display
                          ? [_vm._v(_vm._s(action.display))]
                          : _vm._e(),
                      ],
                      2
                    )
                  }),
                  _vm._t("append-buttons"),
                ],
                2
              ),
            ]
          ),
          _c("div", { staticClass: "secondary" }, [_vm._t("secondary")], 2),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }