// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../img/delimiter.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".context-container[data-v-026dc830] {\n  width: 100%;\n  position: sticky;\n  top: 3rem;\n  z-index: 2;\n}\n.base[data-v-026dc830] {\n  min-height: 115px;\n}\n.main[data-v-026dc830], .primary-buttons[data-v-026dc830], .secondary[data-v-026dc830] {\n  display: flex;\n}\n.main[data-v-026dc830] {\n  min-height: 72px;\n  padding-top: 3px;\n}\n@media (max-width: 991px) {\n.main[data-v-026dc830] {\n    display: block;\n    padding-top: 12px;\n}\n}\n@media (max-width: 991px) {\n.primary-buttons[data-v-026dc830], .secondary[data-v-026dc830] {\n    margin: 0 -12px;\n}\n}\n.prepend[data-v-026dc830] {\n  background-color: transparent !important;\n  justify-content: flex-start;\n  display: flex;\n}\n.secondary[data-v-026dc830] {\n  background-color: transparent !important;\n}\n@media (max-width: 991px) {\n.secondary[data-v-026dc830] {\n    justify-content: flex-start;\n}\n}\n.context-title[data-v-026dc830] {\n  border-top: none;\n  padding: 0 12px;\n  justify-content: space-between;\n  background-color: #fff;\n}\n.context-title.bare[data-v-026dc830] {\n  background-color: transparent;\n}\n.context-title.bound[data-v-026dc830] {\n  position: fixed;\n  top: 0;\n  right: 0;\n  left: calc(16.66667% + 1px);\n}\n@media (max-width: 991px) {\n.context-title.bound[data-v-026dc830] {\n    left: 0;\n}\n}\n@media (max-width: 991px) {\n.context-title[data-v-026dc830] {\n    padding: 0 12px;\n}\n}\n.breadcrumb-item[data-v-026dc830] {\n  display: flex;\n  font-size: 20px;\n  color: #555;\n  font-weight: bold;\n  align-items: center;\n}\n@media (max-width: 991px) {\n.breadcrumb-item[data-v-026dc830] {\n    margin-bottom: 12px;\n}\n}\n.title-delimiter[data-v-026dc830] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  width: 12px;\n  height: 19px;\n  display: inline-block;\n  margin: auto 12px;\n}\n.space_between[data-v-026dc830] {\n  justify-content: space-between;\n}", ""]);
// Exports
module.exports = exports;
