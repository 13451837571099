import Vue from 'vue'
import store from './store'
import { toggleItem } from 'helpers'

export default new Vue({
    data() {
        const expandCollapseButtons = [
            {type: 'expand', run: 'expandAllItems'},
            {type: 'collapse', run: 'collapseAllItems'},
        ]

        const cancelSaveButtons = [
            {type: 'cancel', display: 'Cancel', run: 'clearSelectedItems'},
            {type: 'proceed', display: 'Save', run: 'saveSelectedItems'},
        ]

        return {
            type: '',
            reciever: '',
            editModeActive: false,
            expandedFolders: [],
            actionSets: {
                base: [
                    ...expandCollapseButtons,
                    {type: 'edit', display: 'Edit', run: 'enableEditMode'},
                ],
                edit: [
                    ...expandCollapseButtons,
                    ...cancelSaveButtons,
                ],
                editSelected: [
                    ...expandCollapseButtons,
                    {type: 'duplicate', display: 'Duplicate', run: 'duplicateSelectedItems'},
                    {type: 'edit-multi', display: 'Bulk Edit', run: 'enableEditingModal'},
                    {type: 'delete', display: '', run: () => {console.log('Delete')}},
                    ...cancelSaveButtons,
                ],
            },
        }
    },
    computed: {
        activeActionSet() {
            const sets = this.actionSets

            if (store.state.editableItems.length) {
                return sets.editSelected
            }

            if (this.editModeActive) {
                return sets.edit
            }

            return sets.base
        },
    },
    created() {
        this.$on('reset', () => {
            this.type = ''
            this.reciever = ''
            this.editModeActive = false
            store.dispatch('clearEditableItems')
        })

        this.$on('setType', type => {
            this.type = type
        })

        this.$on('setReciever', reciever => {
            this.reciever = reciever
        })

        this.$on('enableEditMode', () => {
            this.editModeActive = true
        })

        this.$on('disableEditMode', () => {
            this.editModeActive = false
        })

        this.$on('clearSelectedItems', () => {
            this.editModeActive = false
            store.dispatch('clearEditableItems')
        })

        this.$on('saveSelectedItems', () => {
            this.editModeActive = false
            store.dispatch('clearEditableItems')
        })

        this.$on('duplicateSelectedItems', () => {
            store.dispatch('duplicateSelectedItems', {
                type: this.type,
                reciever: this.reciever,
            })
        })

        this.$on('enableEditingModal', () => {
            store.dispatch('enableEditingModal')
        })

        this.$on('toggleFolder', id => {
            this.expandedFolders = toggleItem(this.expandedFolders, id)
        })

        this.$on('expandFolder', id => {
            if (this.expandedFolders.includes(id)) {
                return
            }

            this.expandedFolders = toggleItem(this.expandedFolders, id)
        })

        this.$on('closeFolder', id => {
            if (!this.expandedFolders.includes(id)) {
                return
            }

            this.expandedFolders = toggleItem(this.expandedFolders, id)
        })

        this.$on('collapseAllItems', () => {
            this.expandedFolders = []
        })
    },
})
