<template>
    <div 
        :class="{'line-item--dark': dark}" 
        class="line-item mt-0">
        <slot/>
    </div>
</template>

<script>
export default {
    name: 'LineItem',
    props: {
        dark: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss">
@import '../scss/variables';

.line-item {
    background-color: $grey_light_2;
    position: relative;
    border-top: 1px solid  $grey_light_1;

    &::before {
        content: '';
        background-color: $grey_light_1;
        display: block;
        position: absolute;
        right: 0;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 1px;
        max-height: 1px;
    }

    &--active {
        background-color: $tron;
    }

    &--dark {
        background-color: $grey_light_2;

        &.line-item--active {
            background-color: rgba(#62C5F1, .2);
            color: $blue_mid_1;
        }
    }
}
</style>
