<template>
    <div class="context-container">
        <div
            v-if="bound"
            class="base"
        />

        <line-item
            :class="{ bare, bound, 'context-title': true }"
        >
            <div class="main"
                :class="{space_between: this.spaceBetween}">
                <div v-if="!!$slots.title" >
                    <slot name="title" />
                </div>
                <div v-else class="text-h6">
                  <v-breadcrumbs :items="displayTitle ? breadcrumbDisplayItems : breadcrumbItems">
                      <template v-slot:divider>
                          <span class="title-delimiter"/>
                      </template>
                    <template v-slot:item="{ item }">
                      <v-breadcrumbs-item
                          :disabled="item.disabled"
                      >
                        <span v-if="item.disabled" class="breadcrumb-item">
                          {{ item.text }}
                        </span>
                        <router-link
                            v-else
                            :to="item.to"
                            class="breadcrumb-item"
                        >
                          {{ item.text }}
                        </router-link>
                      </v-breadcrumbs-item>
                    </template>
                  </v-breadcrumbs>
                </div>

                <div class="primary-buttons">
                    <action-button
                        v-for="(action, i) in actions"
                        :key="i"
                        :type="action.type"
                        :action="runAction(action.run)"
                        :noIcon="action.noIcon === undefined ? false : action.noIcon"
                    >
                        <template v-if="action.display">{{ action.display }}</template>
                    </action-button>
                    <slot name="append-buttons"/>
                </div>
            </div>

            <div class="secondary">
                <slot name="secondary"/>
            </div>
        </line-item>
    </div>
</template>

<script>
import bus from '../store/action_bus'
import scrollBus from '../store/scrollBus'
import lineItem from './line_item.vue'
import actionButton from './action_button.vue'

export default {
    name: 'ContextTitle',
    components: {
        lineItem,
        actionButton,
    },
    props: {
        title: {
            type: [Array, String],
            required: true,
        },
        passedActions: [Array, Boolean],
        bare: Boolean,
        spaceBetween: {
            type: Boolean,
            default: true,
        }
    },
    data: () => ({
        bound: false,
        offset: 0,
    }),
    computed: {
        displayTitle() {
            let ret = false

            if (typeof this.title === 'string') {
                ret = this.title.split('> ')
            }

            return ret
        },

        actions() {
            if (this.passedActions) {
                if (this.passedActions === true) {
                    return []
                }

                return this.passedActions
            }

            return bus.activeActionSet
        },

        editableItems() {
            return this.$store.state.editableItems
        },

        breadcrumbItems() {
            return this.title.map(this.convertToBreadcrumbItem);
        },
        breadcrumbDisplayItems() {
            return this.displayTitle.map(this.convertToBreadcrumbItem);
        }
    },
    methods: {
        runAction(action, ...args) {
            if (typeof action === 'function') {
                return () => action(...args)
            }

            return () => bus.$emit(action, ...args)
        },

        convertToBreadcrumbItem(x) {
            return typeof x === "string"
                ? {text: x, disabled: true}
                : {text: x.label, disabled: false, to: x.to};
        }
    },
}
</script>

<style lang="scss" scoped>
@import '../scss/variables';
@import '../scss/mixins';

.context-container {
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    top: 3rem;
    z-index: 2;
}

.base {
    min-height: 115px;
}

.main, .primary-buttons, .secondary {
    display: flex;
}

.main {
    min-height: height(12);
    padding-top: 3px;

    @include below(md) {
        display: block;
        padding-top: 12px;
    }
}

.primary-buttons, .secondary {
    @include below(md) {
        margin: 0 -12px;
    }
}

.prepend {
    background-color: transparent !important;
    justify-content: flex-start;
    display: flex;
}
.secondary {
    // justify-content: flex-end;
    background-color: transparent !important;

    @include below(md) {
        justify-content: flex-start;
    }
}

.context-title {
    border-top: none;
    padding: 0 spacing(xs);
    justify-content: space-between;
    background-color: #fff;

    &.bare {
        background-color: transparent;
    }

    &.bound {
        position: fixed;
        top: 0;
        right: 0;
        left: calc(16.66667% + 1px);

        @include below(md) {
            left: 0;
        }
    }

    @include below(md) {
        padding: 0 spacing(xs);
    }
}

.breadcrumb-item {
    display: flex;
    font-size: fz(venti);
    color: $grey_dark_1;
    font-weight: bold;
    align-items: center;

    @include below(md) {
        margin-bottom: 12px;
    }
}

.title-delimiter {
    @include background-image('', 'delimiter.svg');

    width: 12px;
    height: 19px;
    display: inline-block;
    margin: auto 12px;
}

.space_between {
    justify-content: space-between;
}
</style>
